<template>
  <div class="health">
    <div class="page-title">健康评估参数</div>
    <div class="column-me mT20">
      <div>
        <el-table :data="tableData" border header-cell-class-name="table-header-style"
                  cell-class-name="table-cell-style">
          <el-table-column type="index" label="序号" width="100" align="center"/>
          <el-table-column prop="configName" label="分类" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="configValue" label="内容" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="updateTime" label="最后一次编辑时间" align="center"
                           show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" align="center" show-overflow-tooltip width="150" fixed="right">
            <template #default="scope">
              <div class="row-me center-all">
                <div class="blue-text" @click="clickEdit(scope.row)">编辑</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog title="编辑" v-model="visible" width="1000px" top="10vh" :destroy-on-close="true">
      <div>
        <div class="w100Per">
          <el-input type="textarea" placeholder="请输入" style="width: 100%;" clearable :autosize="{minRows:10}"
                    v-model="detailItem.configValue"></el-input>
        </div>
        <div class="row-me flex-end1 mT30">
          <div class="btn-blank w100 btn-height40 mR20" @click="visible=false">取消</div>
          <div class="btn-blue w100 btn-height40" @click="clickOk">保存</div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      tableData: [],
      detailItem: {},
      visible: false,
    }
  },
  // 侦听器
  watch: {},
  // 计算属性
  computed: {},
  // html加载完成之前
  created() {
    this.getData()
  },
  // html加载完成后执行。
  mounted() {

  },
  // 事件方法执行
  methods: {
    clickEdit(item) {
      this.detailItem = this.$method.deepClone(item)
      this.visible = true
    },
    clickOk() {
      this.$post('/system/config/configWatchUpdate', [{
        configId: this.detailItem.configId,
        configValue: this.detailItem.configValue,
      }]).then((res) => {
        if (res) {
          this.visible = false
          this.$message.success('保存成功')
          this.getData()
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    getData() {
      this.$postForm('/system/config/configHealth', {}).then((res) => {
        if (res) {
          this.tableData = res.data
        }
      }).catch((error) => {
        console.log(error)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.health {
  height: 100%;
  margin: 20px;
  padding: 20px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
</style>
